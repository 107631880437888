<template>
  <KPage fluid>
    <!-- Toolbar de Filtros -->
    <v-toolbar flat class="pl-2">
      <!-- Botão de Atualizar -->
      <v-btn icon @click="refresh">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <!-- Filtro por Código -->
      <v-select
        clearable
        no-data-text="Nada a exibir"
        multiple
        solo
        flat
        :items="filterCodesList"
        item-text="name"
        item-value="id"
        v-model="search.code"
        label="Filtrar por Status HTTP"
        prepend-inner-icon="mdi-filter-variant"
        class="mr-4 mt-7"
      ></v-select>
      <!-- Filtro por Tipo de Erro -->
      <v-select
        clearable
        flat
        no-data-text="Nada a exibir"
        multiple
        :items="filterErrorTypesList"
        item-text="name"
        item-value="id"
        v-model="search.type"
        label="Filtrar por Tipo do Log"
        solo
        prepend-inner-icon="mdi-filter-variant"
        class="mr-4 mt-7"
      ></v-select>
      <!-- Filtro por IP -->
      <v-select
        clearable
        flat
        multiple
        solo
        class="mr-4 mt-7"
        no-data-text="Nada a exibir"
        prepend-inner-icon="mdi-filter-variant"
        label="Filtrar pelo Método HTTP"
        item-text="name"
        item-value="id"
        :items="filterMethodsList"
        v-model="search.method"
      ></v-select>
    </v-toolbar>

    <!-- Caso nenhum resultado tenha sido encontrado -->
    <!-- <v-row no-gutters v-if="!logs.length">
      <v-col>
        <KAlert icon="mdi-information-outline" color="primary"
          >Nada a exibir</KAlert
        >
      </v-col>
    </v-row> -->

    <!-- Resultados -->
    <v-card outlined class="overflow-hidden">
      <v-row no-gutters>
        <v-col>
          <v-list
            two-line
            dense
            class="py-0 bordered"
            v-if="logs && logs.length"
          >
            <template v-for="(log, key) in logs">
              <!-- Item do Log -->
              <item :log="log" :key="key" @click:open="open(log)"></item>
              <!-- Divisor -->
              <!-- <v-divider :key="'div-' + key"></v-divider> -->
            </template>
          </v-list>
        </v-col>
      </v-row>
    </v-card>

    <PaginationBar :pagination="pagination" v-model="page" />

    <!--  -->
    <DialogDetails @changeData="refresh" ref="dialog_details" />

    <!--  -->
  </KPage>
</template>

<script>
import KPage from "@/components/KPage";
import KAlert from "@/components/KAlert";
import PaginationBar from "@/components/Pagination/Bar";

import Item from "./Item";
import DialogDetails from "./DialogDetails";

export default {
  components: {
    KPage,
    KAlert,
    PaginationBar,

    Item,
    DialogDetails
  },

  data() {
    return {
      //
      loading: false,

      // Dados da busca
      search: {
        q: null,
        limit: 20,

        code: null
      },

      state: {
        q: null
      },

      // Armazena os filtros usados para pesquisar nos resultados
      filters: {},

      //
      dialog: {
        visible: true
      },

      //
      page: 1
    };
  },

  watch: {
    page(page) {
      this.loadItems({ ...this.search, page });
    },
    search: {
      deep: true,
      handler() {
        // console.log("watch search", this.search);
        // Quando fizer o filtro, force para a página 1
        if (this.page !== 1) {
          this.page = 1;
        } else {
          this.loadItems({ ...this.search, page: 1 });
        }
      }
    }
  },

  computed: {
    /**
     * Alimenta a lista de cidades de forma reativa
     */
    logs() {
      return this.$store.getters["logs/data"];
    },

    /**
     * Retorna os dados da paginação, como numero de paginas e pagina atual
     */
    pagination() {
      const pagination = this.$store.getters["logs/pagination"];
      // this.page = pagination.page;
      return pagination;
    },

    filterCodesList() {
      try {
        const list = this.filters.code.filter(filter => filter !== null);

        return list || [];
      } catch (error) {
        return [];
      }
    },

    filterErrorTypesList() {
      try {
        const list = this.filters.type.filter(filter => filter !== null);

        return list || [];
      } catch (error) {
        return [];
      }
    },

    filterMethodsList() {
      try {
        const list = this.filters.method.filter(filter => filter !== null);

        return list || [];
      } catch (error) {
        return [];
      }
    }
  },

  //
  methods: {
    /**
     * Abre o dialog de detalhes
     */
    open(data) {
      this.$refs["dialog_details"].openDetails(data);
    },

    /**
     * Atualiza de acordo com o padrão
     */
    refresh() {
      this.loadItems({ ...this.search, page: this.page });
      this.loadFilters();
    },

    /**
     * Carrega os partidos caso ainda não estejam
     */
    async loadItems(data) {
      try {
        // console.log("loadintse", data);
        this.loading = true;
        this.$loading.show();
        await Promise.all([this.$store.dispatch("logs/refresh", data)]);
        this.$loading.hide();
        this.loading = false;
      } catch (error) {
        this.$loading.hide();
        this.loading = false;
        this.$message.serverError(error);
      }
    },

    async loadFilters() {
      try {
        const {
          data: { data }
        } = await this.$api.get("/logs/filters");
        // console.log(data);

        this.filters = data;
      } catch (error) {
        this.$message.serverError(error);
      }
    }
  },

  mounted() {
    this.loadItems({ page: 1 });
    this.loadFilters();
  }
};
</script>

